import React, {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";
import { TeliaAssistiveText } from "@telia/b2x-telia-assistive-text";
import { TeliaFieldError } from "@telia/b2x-telia-field-error";
import { TeliaIcon } from "@telia/b2x-telia-icon";
import { checkBold, minusBold } from "@teliads/icons";
import classnames from "classnames";

import styles from "./b2x-telia-checkbox.module.scss";

const genRandom = () => Math.floor(Math.random() * 1000000);

const uniqueId = (identifier?: string) => {
  const idPart = identifier ? `${identifier}-` : "";
  return `${idPart}${genRandom()}-${genRandom()}`;
};

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  children?: ReactNode;
  dataTestid?: string;
  disabled?: boolean;
  htmlForm?: string;
  value?: string;
  name?: string;
  additional?: string;
  additionalId?: string;
  id?: string;
  checked?: boolean;
  indeterminate?: boolean;
  required?: boolean;
  requiredErrorMessage?: string;
  errorMessage?: string;
  errorId?: string;
  labelledby?: string;
  style?: Record<string, string>;
  ["data-track-value"]?: string;
  ["data-track-click"]?: string;
  ["data-track-configuration"]?: string;
};

export const TeliaCheckbox = ({
  children,
  dataTestid,
  disabled = false,
  htmlForm,
  value,
  name,
  id = uniqueId("id"),
  additional,
  additionalId = uniqueId("additional"),
  checked = false,
  indeterminate = false,
  required = false,
  requiredErrorMessage, // General error message, which overrides other error messages and is shown at initial render
  errorMessage,
  errorId = uniqueId("error"),
  labelledby,
  style,
  ...inputProps
}: Props) => {
  useEffect(() => {
    obsoleteWarning("b2x-telia-checkbox", "inputs-checkbox--docs");
  }, []);

  const [touched, setTouched] = useState(false); // Input has been blurred at least once.
  const [errorMessageState, setErrorMessageState] = useState("");
  const inputElement = useRef() as MutableRefObject<HTMLInputElement>;

  const updateErrorMessage = useCallback((message: string) => {
    inputElement?.current.setCustomValidity(message);
    setErrorMessageState(message);
  }, []);

  const updateErrorState = useCallback(() => {
    if (errorMessage) {
      updateErrorMessage(errorMessage);
      setTouched(true);

      // General error message overrides other errors, exit here.
      return;
    }
    if (!required) {
      return;
    }
    if (touched && !!requiredErrorMessage && !checked) {
      updateErrorMessage(requiredErrorMessage);
    } else {
      // Clear required error
      if (requiredErrorMessage) {
        updateErrorMessage("");
      }
    }
  }, [errorMessage, checked, required, requiredErrorMessage, touched, updateErrorMessage]);

  useEffect(() => {
    // We need this effect in order to execute `setCustomValidity` after the DOM has been drawn
    updateErrorState();
  }, [updateErrorState]);

  useEffect(() => {
    if (htmlForm) {
      inputElement.current.setAttribute("form", htmlForm);
    }
  }, [htmlForm]);

  const showAdditional = Boolean(additional);
  const showError = touched && Boolean(errorMessageState.length);

  return (
    <div
      className={styles[`telia-checkbox${disabled ? " telia-checkbox--disabled" : ""}`]}
      style={style}
    >
      <label htmlFor={id} className={styles["telia-checkbox__main"]}>
        <input
          {...inputProps}
          className={classnames({
            [styles["telia-checkbox__input"]]: true,
            [styles["telia-checkbox__input--touched"]]: touched,
          })}
          type="checkbox"
          id={id}
          data-testid={dataTestid}
          name={name}
          value={value}
          disabled={disabled}
          checked={checked}
          ref={inputElement}
          required={required}
          aria-labelledby={labelledby}
          aria-describedby={classnames({
            [additionalId]: showAdditional,
            [errorId]: showError,
          })}
          onBlur={(event) => {
            setTouched(true);
            updateErrorState();
            inputProps?.onBlur?.(event);
          }}
          onChange={(event) => {
            updateErrorState();
            inputProps?.onChange?.(event);
          }}
          onInvalid={(event) => {
            event.preventDefault();
            setTouched(true);
            updateErrorState();
            inputProps?.onInvalid?.(event);
          }}
          form={htmlForm}
        />
        <div className={styles["telia-checkbox__fake-checkbox-wrapper"]}>
          <div className={styles["telia-checkbox__fake-checkbox"]}>
            <TeliaIcon
              className={styles["telia-checkbox__icon"]}
              svg={indeterminate ? minusBold.svg : checkBold.svg}
              size="sm"
            />
          </div>
        </div>
        {children && <span className={styles["telia-checkbox__label"]}>{children}</span>}
      </label>

      {showAdditional && (
        <TeliaAssistiveText className={styles["telia-checkbox__additional"]} id={additionalId}>
          {additional}
        </TeliaAssistiveText>
      )}

      {showError && (
        <TeliaFieldError id={errorId} className={styles["telia-checkbox__error"]}>
          {errorMessageState}
        </TeliaFieldError>
      )}
    </div>
  );
};
